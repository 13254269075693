/* breakpoint media query */
$small_desktop: 1224px;
$mobile: 768px;

/* background color */
$bg_head_foot : #004f8c;
$bg_dark: #0f121f;
$bg_btn: #1b82d1;
$link_color_hover: #4097ee;
$link_color_hover_2: #004f8c;
$menu_color_hover: #bdd9ff;