/* footer */

footer {
  padding: 0 30px;
  min-height: 70px;
  background-color: $bg_head_foot;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Oxygen', sans-serif;
  font-size: 16px;
  color: #fff;
  clear: both;
  .design_by {
    color: #fff;
    transition: all .4s;
    &:hover {
      color: #bdd9ff;
    }
  }
  @include mq_max (768) {
    padding: 10px 15px 0;
    text-align: center;
  }
  @include mq_max (480) {
    flex-direction: column;
    justify-content: space-around;
  }
}


/* end footer */
