.investment {
  display: flex;
  flex-wrap: wrap;
  .item_investment {
    position: relative;
    width: 50%;
    padding: 4% 10%;
    background-size: cover;
    vertical-align: top;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &:after {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: rgba(15, 18, 31, .2);
    }
    @include mq_max(769) {
      width: 100%;
    }
  }
  .wrap_bg_title {
    width: 100%;
    height: 273px;
    margin: 0 auto;
    background-color: rgba(15, 18, 31, .8);
    transition: all .4s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
    &:hover {
      background-color: rgba(0, 79, 140, .8);
    }
    @include mq_max(1224) {
      height: 187px;
    }
    @include mq_max(768) {
      height: 180px;
    }
  }
  .title_investment {
    font-size: 30px;
    color: #fff;
    line-height: 42px;
    text-align: center;
    margin-bottom: 30px;
    @include mq_max(1224) {
      font-size: 20px;
      line-height: 28px;
    }
    @include mq_max(769) {
      font-size: 30px;
    }
  }
  .short_desc_investment {
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    width: 80%;
    margin: 0 auto;
  }
  @include mq_max(769) {
    display: block;
  }
}


/* Retail investment */

.head_page_retail {
  padding: 114px 0 90px;
  background-color: #0f121f;
  font-weight: 700;
  color: #fff;
  text-align: center;
  .title_page_retail {
    font-size: 45px;
    margin-bottom: 10px;
  }
  .desc_page_retail {
    font-size: 16px;
    width: 53%;
    margin: 0 auto;
    @include mq_max(992) {
      width: 100%;
      padding: 0 15px;
    }
  }
  @include mq_max(992) {
    padding: 60px 0 50px;
  }
}

.retail_investments {
  color: #fff;
  .container {
    width: 80%;
    margin: 0 auto;
    padding: 0px;
    @include mq_max(1440) {
      width: 90%;
    }
    @include mq_max(1200) {
      width: 100%;
    }
  }
}

.item_retail_investment {
  overflow: hidden;
  padding: 50px 0;
  background-color: #0d1b3d;
  .container {
    display: flex;
    @include mq_max(767) {
      display: block;
    }
  }
  &:nth-child(even) {
    background-color: #0f121f;
    .wrap_img_retail {
      order: 1;
    }
  }
  .wrap_img_retail {
    width: 50%;
    float: left;
    padding: 0 15px;
    @include mq_max(767) {
      width: 100%;
      margin-bottom: 20px;
    }
  }
  .wrap_text_retail {
    width: 50%;
    float: left;
    padding: 0 95px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include mq_max(1440) {
      padding: 0 75px;
    }
    @include mq_max(1200) {
      padding: 0 45px;
    }
    @include mq_max(992) {
      padding: 0 15px;
    }
    @include mq_max(767) {
      width: 100%;
    }
  }
  .title_block_retail {
    font-size: 28px;
    font-family: "Crimson Text", sans-serif;
    line-height: 30px;
    padding-bottom: 25px;
    margin-bottom: 20px;
    border-bottom: 1px solid #1b82d1;
  }
  .text_block_retail {
    font-size: 14px;
    line-height: 22px;
  }
}

/* Office investment */

.head_block_office {
  height: 696px;
  background: url(../img/office_center.jpg) center no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  .inner_block {
    width: 50%;
    padding: 48px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 700;
    color: #fff;
    text-align: center;
    background-color: rgba(15, 18, 31, .8);
    @include mq_max(767) {
      width: 80%;
      height: auto;
      padding: 20px 0;
    }
  }
  .title_block_office {
    font-size: 45px;
    line-height: 65px;
    margin-bottom: 5px;
  }
  .text_block_office {
    font-size: 16px;
    line-height: 24px;
    padding: 0 25px;
  }
  @include mq_max(767) {
    height: 400px;
  }
}

.office_investments {
  background-color: #0f121f;
  padding: 100px 0;
  color: #fff;
  .container {
    width: 70%;
    margin: 0 auto;
    @include mq_max(1440) {
      width: 80%;
    }
    @include mq_max(1200) {
      width: 90%;
    }
    @include mq_max(992) {
      width: 95%;
    }
    @include mq_max(767) {
      width: 100%;
      padding: 0 15px;
    }
  }
  .text_office_investments {
    width: 50%;
    float: left;
    font-size: 14px;
    line-height: 22px;
    text-align: justify;
    &:nth-child(1) {
      padding-right: 105px;
      @include mq_max(1200) {
        padding-right: 55px;
      }
      @include mq_max(992) {
        padding-right: 25px;
      }
    }
    &:nth-child(2) {
      padding-left: 105px;
      @include mq_max(1200) {
        padding-left: 55px;
      }
      @include mq_max(992) {
        padding-left: 25px;
      }
    }
    @include mq_max(767) {
      width: 100%;
      padding: 0px !important;
      margin-bottom: 20px;
    }
  }
  .bottom_text_office_investments {
    clear: both;
    font-size: 18px;
    line-height: 27px;
    width: 50%;
    padding: 60px 0 40px;
    margin: 0 auto;
    text-align: center;
    @include mq_max(1200) {
      width: 80%;
    }
    @include mq_max(767) {
      padding-top: 0px;
    }
    @include mq_max(767) {
      width: 100%;
    }
  }
  .link_more {
    width: 199px;
    height: 50px;
    margin: 0 auto;
    position: absolute;
    bottom: 40px;
    left: 50%;
    margin-left: -99px;
  }
  @include mq_max(1440) {
    padding: 80px 0;
  }
  @include mq_max(992) {
    padding: 60px 0;
  }
  @include mq_max(767) {
    padding: 40px 0;
  }
}

/* Engineered Products */

.engineered_products {
  display: flex;
  @include mq_max(767) {
    display: block;
  }
  .item_engineered_products {
    position: relative;
    width: 50%;
    float: left;
    color: #fff;
    text-align: center;
    background-color: #0f121f;
    transition: all .4s;
    cursor: pointer;
    &:hover {
      background-color: #0d1b3d;
    }
    @include mq_max(767) {
      width: 100%;
    }
  }
  .wrap_text_engineered {
    width: 70%;
    margin: 0 auto;
    padding: 40px 0;
    @include mq_max(1200) {
      width: 80%;
    }
    @include mq_max(992) {
      width: 100%;
      padding: 40px 25px;
    }
    @include mq_max(767) {
      padding: 30px 15px;
    }
  }
  .title_block_engineered {
    font-size: 30px;
    line-height: 42px;
    padding-bottom: 20px;
    margin-bottom: 15px;
    border-bottom: 1px solid #1B82D1;
  }
  .place_block_engineered {
    font-size: 18px;
    line-height: 25px;
    font-weight: 700;
    margin-bottom: 50px;
  }
  .text_block_engineered {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 110px;
  }
  .link_more {
    width: 199px;
    height: 50px;
    margin: 0 auto;
    position: absolute;
    bottom: 40px;
    left: 50%;
    margin-left: -99px;
  }
}

.company {
  .tabs-menu {
    overflow: hidden;
    font-size: 0;
    li {
      width: 50%;
      height: 78px;
      line-height: 78px;
      float: left;
      text-align: center;
      background-color: #1b82d1;
      list-style-type: none;
      @include mq_max(992) {
        height: 60px;
        line-height: 60px;
      }
    }
    li.current {
      position: relative;
      background-color: #0d1b3d;
    }
    li a {
      display: block;
      font-size: 26px;
      color: #fff;
      @include mq_max(767) {
        font-size: 22px;
      }
      @include mq_max(480) {
        font-size: 20px;
      }
      @include mq_max(360) {
        font-size: 16px;
      }
    }
  }
}

.tab {
  padding-top: 60px;
  background-color: #0d1b3d;
  .tab-content {
    width: 80%;
    margin: 0 auto;
    display: none;
    @include mq_max(1200) {
      width: 90%;
    }
    @include mq_max(992) {
      width: 100%;
    }
  }
  #tab-1 {
    display: block;
  }
  @include mq_max(767) {
    padding-top: 20px;
  }
}

.company {
  .about_company {
    font-size: 18px;
    line-height: 27px;
    color: #fff;
    text-align: center;
    padding: 0 25%;
    margin-bottom: 20px;
    @include mq_max(1200) {
      padding: 0 45px;
    }
    @include mq_max(992) {
      padding: 0 25px;
    }
  }
}

.item_about_company {
  overflow: hidden;
  padding: 50px 0;
  color: #fff;
  display: flex;
  &:nth-child(odd) {
    .wrap_img_retail {
      order: 1;
    }
  }
  .wrap_img_retail {
    width: 50%;
    float: left;
    padding: 0 15px;
    @include mq_max(767) {
      width: 100%;
      margin-bottom: 20px;
    }
  }
  .wrap_text_retail {
    width: 50%;
    float: left;
    padding: 0 95px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include mq_max(1440) {
      padding: 0 75px;
    }
    @include mq_max(1200) {
      padding: 0 45px;
    }
    @include mq_max(992) {
      padding: 0 15px;
    }
    @include mq_max(767) {
      width: 100%;
    }
  }
  .title_block_retail {
    font-size: 22px;
    font-family: "Crimson Text", sans-serif;
    line-height: 33px;
    margin-bottom: 20px;
  }
  .text_block_retail {
    font-size: 16px;
    line-height: 24px;
  }
  @include mq_max(767) {
    display: block;
  }
}

.title_management {
  font-size: 26px;
  line-height: 39px;
  color: #fff;
  text-align: center;
  font-family: "Crimson Text", sans-serif;
  margin-bottom: 80px;
  @include mq_max(992) {
    margin-bottom: 50px;
  }
  @include mq_max(767) {
    margin-bottom: 30px;
  }
}

.management {
  font-size: 0;
  padding-bottom: 25px;
}

.item_management {
  width: 25%;
  display: inline-block;
  vertical-align: top;
  padding: 0 20px;
  margin-bottom: 75px;
  color: #fff;
  .wrap_img_management {
    margin-bottom: 20px;
    text-align: center;
    max-height: 321px;
    overflow: hidden;
  }
  .name_management {
    font-size: 22px;
    font-family: "Crimson Text", sans-serif;
    line-height: 28px;
    margin-bottom: 20px;
  }
  .position_management {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }
  @include mq_max(1200) {
    width: 33.3%;
    margin-bottom: 50px;
  }
  @include mq_max(767) {
    width: 50%;
    margin-bottom: 40px;
  }
  @include mq_max(480) {
    width: 100%;
  }
}


/* 404 */

.error404 {
  main {
    margin-top: 0;
  }
}

.wrap_not_found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  flex: 1 0 auto;
  font-size: 26px;
  font-weight: 700;
}

.contact{
  .name_management{
    font-size: 20px;
    margin-bottom: 5px;
  }
  .position_management{
    font-weight: 500;
  }
}
.estate {
  font-family: 'Oxygen', sans-serif;
  position: relative;
  min-height: 700px;
  .slick-slider {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
  }
  .slick-list {
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
  }
  .slick-track {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
  }
  
  p {
    margin-bottom: 15px;
    line-height: 150%;
    font-size: 12px;
  }
  .btn {
    width: 199px;
    height: 50px;
    margin: 0 auto;
  }
  .estate_banners {
    .item_estate_banner {
      //min-height: 700px;
      height: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  .investment {
    padding: 15px;
    z-index: 9;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .investment-wrap {
      text-align: center;
      margin: 0 auto;
      max-width: 980px;
      width: 100%;
      padding: 15px;
      border-width: 0px;
      border-radius: 0px;
      border-color: rgb(200, 200, 200);
      border-style: solid;
      background-color: rgba(15, 18, 31, 0.8);
      background-image: none;
      background-size: auto;
      background-repeat: repeat;
      background-position: center center;
      color: #fff;
      .learn_more {
        margin-bottom: 10px;
        font-size: 12px;
        display: block;
        text-align: center;
      }
    }
  }
  .head_page {
    background: none;
    padding: 0;
    width: 100%;
    text-align: center;
    font-size: 20px;
    line-height: 140%;
    padding-bottom: 15px;
  }

  .investlist {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 450px;
    margin: 0 auto;
    list-style: none;
    margin-bottom: 15px;
    &.nopadding {
      padding-bottom: 0px;
      margin-bottom: 0;
    }
    li {
      position: relative;
      margin-bottom: 15px;
      font-size: 12px;
      text-align: left;
      width: 40%;
      padding-left: 10px;
      &:after {
        content: "";
        position: absolute;
        height: 4px;
        width: 4px;
        left: 0;
        top: 50%;
        margin-top: -2px;
        background-color: #fff;
        border-radius: 50%;
      }
    }
    li:nth-of-type(even) {
      width: 50%;
      text-align: left;
    }
  }

  @include mq_min(480) {

  }
  @include mq_min(768) {
    .investment {
      padding: 15px;
      .investment-wrap {
        max-width: 980px;
        //max-height: 508px;
        padding: 50px;
        .learn_more {
          margin-bottom: 11px;
          font-size: 16px;
          display: block;
          text-align: center;
        }
      }
    }
    .head_page {
      font-size: 45px;
      padding-bottom: 20px;
      line-height: 1;
    }
    p {
      margin-bottom: 40px;
      font-size: 16px;
    }
    .estate_banners {
      .item_estate_banner {
        //height: 900px;
      }
    }
    .investlist {
      padding-left: 6%;
      padding-bottom: 21px;
      margin-bottom: 0;
      li {
        margin-bottom: 30px;
        text-align: left;
        width: 40%;
        font-size: 20px;
      }
      li:nth-of-type(even) {
        width: 54%;
        text-align: left;
        font-size: 20px;
      }
    }
  }
  @include mq_min(1024) {
    .investment {
      padding: 15px;
      .investment-wrap {
        //height: 508px;
        .learn_more {
          margin-bottom: 30px;
          display: block;
          text-align: center;
        }
      }
    }
  }
}



