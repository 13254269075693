/* main */

.home {
  main {
    display: block;
  }
}

.home_banner {
  position: relative;
  .item_home_banner {
    height: 836px;
    background-size: cover;
    background-position: center 30%;
    background-repeat: no-repeat;
    h2 {
      width: 40%;
      margin: auto;
      font-size: 45px;
      font-weight: 700;
      font-family: 'Oxygen', sans-serif;
      color: #fff;
      text-align: center;
      @include mq_max (1224) {
        width: 60%;
        font-size: 35px;
      }
      @include mq_max (768) {
        font-size: 32px;
      }
      @include mq_max (600) {
        width: 70%;
      }
    }
    &>div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      background-color: rgba(15, 18, 31, .4);
    }
    @include mq_max(1224) {
      height: 700px;
    }
    @include mq_max(768) {
      height: 350px;
    }
    @include mq_max(400) {
      height: 550px;
    }
  }
  .item_home_banner_1 {
    background-image: url(../img/home-slide1.jpg);
  }
  .item_home_banner_2 {
    background-image: url(../img/home-slide2.jpg);
  }
  .btn-prev {
    left: 40px;
    @include mq_max(480) {
      left: 5px;
      top: initial;
      bottom: 10px;
      margin-top: 0px;
    }
  }
  .btn-next {
    right: 40px;
    @include mq_max(480) {
      right: 5px;
      top: initial;
      bottom: 10px;
      margin-top: 0px;
    }
  }
  @include mq_max(768) {
    .btn-prev:before,
    .btn-prev:after,
    .btn-next:before,
    .btn-next:after {
      width: 29px;
      height: 2px;
    }
    .btn-prev:before {
      top: 50px;
    }
    .btn-prev:after {
      top: 30px;
    }
    .btn-next:after {
      top: 30px;
    }
    .btn-next:before {
      top: 50px;
    }
  }
}

.short_desc {
  display: flex;
  height: 431px;
  background-color: $bg_dark;
  color: #fff;
  text-align: center;
  font-family: 'Oxygen', sans-serif;
  .inner_short_desc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    width: 32%;
    @include mq_max(1440) {
      width: 50%;
    }
    @include mq_max(992) {
      width: 75%;
    }
    @include mq_max(768) {
      padding: 50px 10px;
    }
    @include mq_max(480) {
      width: 100%;
    }
  }
  .title_short_desc {
    margin-bottom: 30px;
  }
  .text_short_desc {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 40px;
  }
  .link_more {
    width: 199px;
    height: 50px;
    margin: 0 auto;
  }
  @include mq_max(768) {
    height: 444px;
  }
  @include mq_max(768) {
    height: auto;
  }
}

.portfolio_category {
  display: flex;
  font-size: 0;
  .item_cat_real_estate {
    background: url(../img/realestateinvest-bg.jpg) center no-repeat;
  }
  .item_cat_hotel {
    background: url(../img/hotels-bg.jpg) center no-repeat;
  }
  .item_cat_engineered {
    background: url(../img/egineered-bg.jpg) center no-repeat;
  }
  .item_portfolio_category {
    position: relative;
    width: 33.3333%;
    padding: 4% 5%;
    background-size: cover;
    vertical-align: top;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &:after {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: rgba(15, 18, 31, .2);
    }
    @include mq_max(769) {
      width: 100%;
    }
  }
  .wrap_bg_title {
    width: 100%;
    height: 273px;
    margin: 0 auto;
    background-color: rgba(15, 18, 31, .8);
    transition: all .4s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
    &:hover {
      background-color: rgba(0, 79, 140, .8);
    }
    @include mq_max(1224) {
      height: 187px;
    }
    @include mq_max(768) {
      height: 180px;
    }
  }
  .title_cat {
    font-size: 30px;
    font-family: 'Oxygen', sans-serif;
    color: #fff;
    line-height: 42px;
    text-align: center;
    @include mq_max(1224) {
      font-size: 20px;
      line-height: 28px;
    }
    @include mq_max(769) {
      font-size: 30px;
    }
  }
  @include mq_max(769) {
    display: block;
  }
}

.featured_news {
  background-color: $bg_dark;
  overflow: hidden;
  padding: 70px 0 100px;
  .title_featured_news {
    margin-bottom: 30px;
    text-align: center;
  }
  .inner_slide {
    width: 872px;
    margin: 0 auto;
    @include mq_max (1224) {
      width: 80%;
    }
    @include mq_max (768) {
      width: 70%;
    }
  }
  .item_slide {
    .title_news a {
      font-size: 22px;
      line-height: 30px;
      font-family: "Crimson Text", sans-serif;
      color: #fff;
      display: inline-block;
      margin-bottom: 20px;
      transition: all .4s;
      &:hover {
        color: $link_color_hover_2;
      }
      @include mq_max (769) {
        line-height: 22px;
        margin-top: 10px;
      }
    }
    .post_data_news {
      margin-bottom: 20px;
      a,
      span {
        font-size: 14px;
        font-family: 'Oxygen', sans-serif;
        line-height: 19px;
        color: $link_color_hover;
      }
    }
    .excerpt_news {
      font-size: 16px;
      font-family: 'Oxygen', sans-serif;
      line-height: 22px;
      color: #fff;
      margin-bottom: 30px;
    }
    .read_more_news {
      width: 179px;
      height: 50px;
      @include mq_max (768) {
        margin: 0 auto;
      }
    }
    &:focus {
      outline: none;
    }
  }
  .thumb_news {
    width: 47%;
    float: left;
    @include mq_max (768) {
      float: none;
      width: 100%;
      margin: 0 auto;
    }
  }
  .short_desc_news {
    float: left;
    width: 53%;
    padding-left: 30px;
    @include mq_max (768) {
      float: none;
      width: 100%;
      margin: 0 auto;
      padding-left: 0px;
    }
  }
  .btn-prev {
    left: 20%;
    @include mq_max (1800) {
      left: 15%;
    }
    @include mq_max (1600) {
      left: 5%;
    }
    @include mq_max (1224) {
      left: 2%;
    }
    @include mq_max(480) {
      left: 5px;
      top: initial;
      bottom: -88px;
      margin-top: 0px;
    }
  }
  .btn-next {
    right: 20%;
    @include mq_max (1800) {
      right: 15%;
    }
    @include mq_max (1600) {
      right: 5%;
    }
    @include mq_max (1224) {
      right: 2%;
    }
    @include mq_max(480) {
      right: 5px;
      top: initial;
      bottom: -88px;
      margin-top: 0px;
    }
  }
  .btn_control:hover {
    &:before,
    &:after {
      background-color: rgba(0, 79, 140, 1);
    }
  }
  .btn_control:before,
  .btn_control:after {
    background-color: rgba(0, 79, 140, .8);
    transition: all .4s;
  }
  .slick-dots {
    bottom: -60px;
  }
  .slick-dots li {
    border: 1px solid $link_color_hover;
  }
  .slick-dots li.slick-active {
    background-color: $link_color_hover;
  }
  .slick-dots li button {
    display: none;
  }
}


/* end main */
