.head_page {
  background-color: #0f121f;
  font-size: 45px;
  font-weight: 700;
  color: #fff;
  line-height: 63px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 0 80px;
  &.head_page_small {
    min-height: 207px; 
    padding: 0px;
  }
  .title_page {
    margin-bottom: 20px;
  }
  .subtitle_page {
    width: 60%;
    margin: 0 auto;
    font-size: 16px;
    line-height: 24px;
  }
  @include mq_max(1200) {
    line-height: 63px;
    &.head_page_small {
      min-height: 150px; 
    }
  }
  @include mq_max(992) {
    font-size: 40px;
    line-height: 58px;
    padding: 25px 0;
    .subtitle_page {
      width: 80%;
    }
  }
  @include mq_max(768) {
    font-size: 40px;
    line-height: 48px;
    .subtitle_page {
      width: 95%;
    }
    &.head_page_small {
      min-height: 80px; 
    }
  }
  @include mq_max(480) {
    font-size: 30px;
  }
}

.news_page {
  width: 80%;
  overflow: hidden;
  margin: 0 auto;
  padding: 40px 0;
  .widgettitle {
    font-size: 20px;
    font-weight: 700;
    color: #000;
    padding-bottom: 5px;
    margin-bottom: 25px;
    border-bottom: 4px solid #4097EE;
  }
  .input_search {
    width: 100%;
    height: 49px;
    padding-left: 15px;
    font-size: 16px;
    color: #fff;
    background-color: #4097EE;
    border: none;
    @include placeholder {
      color: #fff;
      opacity: 1;
    }
    &:focus {
      @include placeholder {
        color: transparent;
      }
    }
  }
  #searchform {
    position: relative;
  }
  #searchsubmit {
    position: absolute;
    top: 15px;
    right: 13px;
    background-color: transparent;
    border: none;
  }
  .widget {
    margin-bottom: 50px;
  }
  .widget_recent_entries {
    li {
      margin-bottom: 30px;
    }
  }
  .widget li {
    list-style-type: none;
    &.current-cat{
      a{
        color: #004f8c;
      }
    }
  }
  .widget li a {
    font-size: 16px;
    line-height: 28px;
    color: #000;
    transition: all .4s;

    &:hover {
      color: #004f8c;
    }
  }
  .item_post {
    overflow: hidden;
    clear: both;
    margin-bottom: 40px;
    .thumb_news {
      width: 50%;
      float: left;
      @include mq_max(992) {
        width: 100%;
        float: none;
      }
    }
    &.not_thumb {
      .short_desc_news {
        padding-left: 0px;
      }
    }
    .short_desc_news {
      overflow: hidden;
      padding-left: 30px;
      @include mq_max(992) {
        padding-top: 10px;
        padding-left: 15px;
      }
      @include mq_max(769) {
        padding-left: 0px;
      }
    }
    .title_news a {
      font-size: 22px;
      line-height: 30px;
      font-family: "Crimson Text", sans-serif;
      color: $bg_dark;
      display: inline-block;
      margin-bottom: 20px;
      transition: all .4s;
      &:hover {
        color: $link_color_hover_2;
      }
      @include mq_max (769) {
        line-height: 22px;
        margin-top: 10px;
      }
    }
    .post_data_news {
      margin-bottom: 20px;
      a,
      span {
        font-size: 14px;
        line-height: 19px;
        color: #004f8c;
      }
    }
    .excerpt_news {
      font-size: 16px;
      line-height: 22px;
      color: $bg_dark;
      margin-bottom: 30px;
    }
    .read_more_news {
      width: 179px;
      height: 50px;
    }
  }
  .pagination {
    text-align: center;
    .pages {
      display: none;
    }
    .wp-pagenavi {
      position: relative;
      padding-bottom: 40px;
    }
    .previouspostslink,
    .nextpostslink {
      font-size: 14px;
      color: #000;
      @include mq_max (767) {
        font-size: 20px;
        width: 30px;
        height: 30px;
        display: inline-block;
      }
    }
    .last,
    .first {
      font-size: 14px;
      color: #000;
      @include mq_max (767) {
        font-size: 20px;
        width: 30px;
        height: 30px;
        display: inline-block;
      }
    }
    .first {
      margin-right: 10px;
    }
    .last {
      margin-left: 10px;
    }
    .page {
      padding: 0 30px;
      font-size: 14px;
      color: #000;
      line-height: 39px;
      @include mq_max(767) {
        padding: 0 20px;
        line-height: 30px;
      }
    }
    .extend {
      @include mq_max(767) {
        display: none;
      }
    }
    .current {
      padding: 0 30px;
      display: inline-block;
      color: #fff;
      line-height: 39px;
      background-color: #1b82d1;
      @include mq_max(767) {
        padding: 0 20px;
        line-height: 30px;
      }
    }
    .text_pag {
      @include mq_max(767) {
        display: none;
      }
    }
    .arrow_pag {
      display: inline;
      @include mq_min(768) {
        display: none;
      }
    }
    .to_arrow_pag {
      @include mq_max(767) {
        display: none;
      }
    }
  }
  @include mq_max(1200) {
    width: 100%;
  }
}

.post {
  padding-bottom: 65px;
  .thumb_news {
    width: 59%;
    float: left;
    margin-bottom: 15px;
    @include mq_max(992) {
      width: 100%;
    }
    @include mq_max(769) {
      float: none;
    }
  }
  .wrap_contact_info {
    overflow: hidden;
    padding-left: 30px;
    &.not_thumb {
      padding-left: 0px;
    }
    @include mq_max(992) {
      padding-top: 10px;
      padding-left: 15px;
    }
    @include mq_max(769) {
      padding-left: 0px;
    }
  }
  .title_news {
    font-size: 26px;
    line-height: 36px;
    font-family: "Crimson Text", sans-serif;
    color: $bg_dark;
    display: inline-block;
    margin-bottom: 20px;
    @include mq_max (769) {
      line-height: 22px;
      margin-top: 10px;
    }
  }
  .post_data_news {
    margin-bottom: 20px;
    a,
    span {
      font-size: 14px;
      line-height: 19px;
      color: #004f8c;
    }
  }
  .contact_ {
    font-size: 16px;
    color: #0f121f;
    margin-bottom: 25px;
    line-height: 22px;
    h5 {
      font-size: 18px;
      font-weight: 700;
      color: #000;
      line-height: 25px;
      margin-bottom: 5px;
    }
    a {
      color: #0f121f;
    }
    a:hover {
      span {
        color: #4097ee !important;
      }
    }
  }
  .text_post {
    font-size: 16px;
    color: #0f121f;
    line-height: 22px;
    clear: both;
    margin-top: 40px;
    @include mq_max(992) {
      margin-top: 0px;
    }
  }
  .read_more_news {
    width: 206px;
    margin: 40px auto 0;
  }
  @include mq_max(1200) {
    padding: 0 15px 20px;
  }
}

.right_sidebar {
  width: 20%;
  float: left;
  padding: 0 15px;
  @include mq_max(1440) {
    width: 25%;
  }
  @include mq_max(1200) {
    width: 30%;
  }
  @include mq_max(769) {
    margin-top: 50px;
    width: 100%;
  }
}

.posts {
  width: 80%;
  float: right;
  padding: 0 15px;
  @include mq_max(1440) {
    width: 75%;
  }
  @include mq_max(1200) {
    width: 70%;
  }
  @include mq_max(769) {
    width: 100%;
  }
}

