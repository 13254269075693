.contact {
  overflow: hidden;
  padding: 45px 0;
  background-color: #0d1b3d;
  .container {
    width: 64%;
    margin: 0 auto;
    @include mq_max(1440) {
      width: 75%;
    }
    @include mq_max(1200) {
      width: 80%;
    }
    @include mq_max(992) {
      width: 100%;
    }
  }
  .title_block_contact {
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    line-height: 28px;
    margin-bottom: 40px;
    padding-bottom: 5px;
    border-bottom: 4px solid #4097EE;
    overflow: hidden;
    span {
      float: right;
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      @include mq_max(480) {
        float: none;
        display: block;
      }
    }
  }
  .wrap_form {
    width: 75%;
    float: left;
    padding: 0 15px;
    margin-bottom: 80px;
    @include mq_max(768) {
      width: 100%;
      margin-bottom: 40px;
    }
  }
  .inner_form {
    margin: 0 -15px;
    font-size: 0;
  }
  .form_group {
    width: 50%;
    display: inline-block;
    margin-bottom: 30px;
    padding: 0 15px;
    vertical-align: top;
    @include mq_max(480) {
      width: 100%;
    }
  }
  .form_group_full {
    width: 100%;
    padding: 0 15px;
    margin-bottom: 30px;
  }
  label {
    display: inline-block;
    font-size: 16px;
    color: #fff;
    line-height: 28px;
    margin-bottom: 5px;
  }
  .form_control {
    width: 100%;
    height: 40px;
    border: none;
    padding-left: 10px;
    background-color: #fff;
    font-size: 14px;
    color: #000;
  }
  textarea.form_control {
    min-height: 160px;
    padding-top: 10px;
  }
  .btn_send {
    float: right;
    border: none;
    width: 180px;
  }
  .contact_data {
    width: 25%;
    float: left;
    padding: 0 15px;
    font-size: 16px;
    line-height: 28px;
    color: #fff;
    a {
      color: #fff;
    }
    @include mq_max(768) {
      width: 100%;
      margin-bottom: 40px;
    }
  }
  .management {
    clear: both;
    padding: 0 15px;
    font-size: 0;
  }
  .item_management {
    width: 25%;
    margin-bottom: 55px;
    display: inline-block;
    vertical-align: top;
    font-size: 16px;
    color: #fff;
    line-height: 28px;
    .name_management {
      width: 100%;
      font-weight: 700;
      @include mq_max(1440) {
        width: 100%;
      }
      @include mq_max(768) {
        width: 100%;
      }
    }
    .position_management {
      width: 80%;
      @include mq_max(1440) {
        width: 80%;
      }
      @include mq_max(768) {
        width: 100%;
      }
    }
    @include mq_max(1200) {
      width: 33.3%;
      margin-bottom: 30px;
    }
    @include mq_max(768) {
      width: 50%;
      padding: 0 20px 0 0;
    }
    @include mq_max(480) {
      width: 100%;
      padding: 0px;
    }
  }
  .wpcf7-response-output {
    color: #fff;
    font-size: 16px;
    margin: 3em 0.5em 1em;
    border-color: red;
  }
  .wpcf7-not-valid {
    border: 1px solid red;
  }
  span.wpcf7-not-valid-tip {
    font-size: 16px;
    display: none;
  }
  .wpcf7-response-output.wpcf7-validation-errors,
  .wpcf7-response-output.wpcf7-spam-blocked {
    clear: both;
    position: relative;
    top: 15px;
    margin: 3em 15px 1em;
    border: none;
    color: red;
    clear: both;
  }
  .wpcf7-response-output.wpcf7-mail-sent-ok {
    border: none;
    clear: both;
  }
  .wrap_captcha {
    .wpcf7-form-control-wrap {
      float: left;
      @include mq_max(650) {
        float: right;
        margin-bottom: 20px;
      }
    }
    @include mq_max(650) {
      .wrap_btn_send {
        clear: both;
      }
    }
    @include mq_max(480) {
      .wpcf7-form-control-wrap {
        float: none;
      }
      iframe {
        transform: scale(0.9);
        margin-left: -15px;
      }
    }
  }
}

#map {
  height: 450px;
  // @include mq_max(480) {
  //   height: 300px;
  // }
  @media screen and (max-width: 768px) and (orientation: landscape) {
    height: 200px;
  }
}
