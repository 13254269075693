/* header */

@include mq_max (782) {
  html {
    margin-top: 0px !important;
  }
}

.admin-bar {
  header {
    top: 32px;
    @include mq_max (992) {
      .wrap_nav_menu {
        padding-top: 156px;
      }
    }
    @include mq_max (782) {
      top: 46px;
      .wrap_nav_menu {
        padding-top: 124px;
      }
    }
    @include mq_max (600) {
      top: 0px;
      .wrap_nav_menu {
        padding-top: 80px;
      }
    }
  }
}

@include mq_max (600) {
  body:not(.admin-bar) main {
    margin-top: 80px;
  }
}

// #wpadminbar {
//   @include mq_max (600) {
//     display: none;
//   }
// }
header {
  padding: 0 12px 0 30px;
  position: fixed;
  top: 0px;
  width: 100%;
  height: 124px;
  background-color: $bg_head_foot;
  z-index: 10;
  .logo {
    position: relative;
    height: 124px;
    top: 9px;
    float: left;
    img {
      position: absolute;
      max-width: 446px;
      top: 13px;
      left: 0;
      transition: opacity .4s ease-in-out;
      @include mq_max(1224) {
        width: 380px;
        top: 20px;
      }
      @include mq_max (768) {
        width: 300px;
      }
      @include mq_max (480) {
        width: 210px;
      }
      @include mq_max (768) {
        top: 2px;
        z-index: 12
      }
    }
    &:hover {
      .logo_common {
        opacity: 0;
      }
    }
    @include mq_max (768) {
      top: 12px;
    }
    @include mq_max (480) {
      top: 20px;
    }
  }
  .nav_menu {
    text-align: right;
    list-style-type: none;
    &>li {
      padding: 0 18px;
      line-height: 124px;
      display: inline-block;
      &>a {
        display: block;
        text-transform: uppercase;
      }
      @include mq_max(1224) {
        padding: 0 10px;
      }
    }
    a {
      font-size: 18px;
      font-family: 'Oxygen', sans-serif;
      color: #fff;
      transition: all .3s;
      &:hover {
        color: $menu_color_hover;
      }
    }
    .dropdown {
      position: relative;
      &.open {
        .sub-menu {
          height: 114px;
        }
        .dropdown_toggle:after {
          transform: rotate(180deg);
        }
      }
      .dropdown_toggle {
        position: relative;
        &.open {
          &:after {
            transform: rotate(180deg);
          }
        }
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          margin-top: -12px;
          width: 24px;
          height: 24px;
          background: url(../img/icon_dropdown.svg);
          transition: transform .4s;
        }
      }
      .sub-menu {
        position: absolute;
        left: -50%;
        width: 200%;
        top: 124px;
        background-color: $bg_head_foot;
        list-style: none;
        transition: height .3s linear;
        height: 0px;
        overflow: hidden;
        @include mq_min (993) {
          overflow: hidden;
        }
      }
      li {
        line-height: 36px;
        text-align: center;
      }
      @include mq_max (1224) {
        padding-right: 25px;
      }
      @include mq_max (992) {
        padding-right: 10px;
      }
    }
    @media only screen and (max-width: 600px) and (orientation: portrait) {
      top: 80px;
    }
    @include mq_max (767) {
      top: 80px;
      overflow-y: auto;
      height: 100%;
    }
    @media only screen and (max-width: 600px) and (orientation: landscape) {
      height: 220px;
      overflow-y: auto;
    }
  }
  @include mq_max (768) {
    height: 80px;
  }
  @include mq_max (992) {
    .wrap_nav_menu {
      background: $bg_head_foot;
      width: 225px;
      height: 100%;
      padding-top: 124px;
      top: 0px;
      right: -240px;
      transition: all .6s;
      position: fixed;
      z-index: 9;
      &.show {
        right: 0px;
        overflow-y: auto;
      }
      li {
        display: block;
        line-height: 48px;
      }
      .dropdown {
        .dropdown_toggle {
          padding-right: 20px;
        }
        .sub-menu {
          position: relative;
          left: 0;
          width: 100%;
          top: initial;
          li {
            text-align: right;
          }
        }
      }
    }
  }
  @include mq_max (992) {
    .wrap_nav_menu {
      padding-top: 80px;
    }
  }
}

// vars
$color: #fff; // icon color
$animation: 0.6s; // animation speed
$scale: 0.8; // icon scale 68/68 default
.hamburglar {
  transform: scale($scale);
  position: absolute;
  top: 20px;
  right: 20px;
  display: block;
  width: 68px;
  height: 68px;
  -webkit-touch-callout: none;
  user-select: none;
  cursor: pointer;
  z-index: 11;
  @include mq_min(993) {
    display: none;
  }
  @include mq_max (768) {
    top: 5px;
    right: 10px;
  }
}

// transition mask
.path-burger {
  position: absolute;
  top: 0;
  left: 0;
  height: 68px;
  width: 68px;
  // two masks because... browser support.
  mask: url(#mask);
  -webkit-mask-box-image: url(https://raygun.io/upload/mask.svg);
}

.animate-path {
  position: absolute;
  top: 0;
  left: 0;
  width: 68px;
  height: 68px;
}

// what this does is create a small square that I then rotate behind an svg mask, giving the apparence of the line animating
.path-rotation {
  height: 34px;
  width: 34px;
  margin: 34px 34px 0 0;
  transform: rotate(0deg);
  transform-origin: 100% 0;
  &:before {
    content: none;
    display: block;
    width: 30px;
    height: 34px;
    margin: 0 4px 0 0;
    background: $color;
  }
}

// box rotation animation
@keyframes rotate-out {
  0% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate-in {
  0% {
    transform: rotate(360deg);
  }
  40% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

// offset moves
// dasharray is the dash size
// need to be able to control dash space size.
.hamburglar.is-open {
  .path {
    animation: dash-in $animation linear normal;
    animation-fill-mode: forwards;
  }
  .animate-path {
    animation: rotate-in $animation linear normal;
    animation-fill-mode: forwards;
  }
}

.hamburglar.is-closed {
  .path {
    animation: dash-out $animation linear normal;
    animation-fill-mode: forwards;
  }
  .animate-path {
    animation: rotate-out $animation linear normal;
    animation-fill-mode: forwards;
  }
}

.path {
  stroke-dasharray: 240;
  stroke-dashoffset: 240;
  stroke-linejoin: round;
}

@keyframes dash-in {
  0% {
    stroke-dashoffset: 240;
  }
  40% {
    stroke-dashoffset: 240;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dash-out {
  0% {
    stroke-dashoffset: 0;
  }
  40% {
    stroke-dashoffset: 240;
  }
  100% {
    stroke-dashoffset: 240;
  }
}

// All good burgers need filling!
.burger-icon {
  position: absolute;
  padding: 20px 16px;
  height: 68px;
  width: 68px;
}

.burger-container {
  position: relative;
  height: 28px;
  width: 36px;
}

.burger-bun-top,
.burger-bun-bot,
.burger-filling {
  position: absolute;
  display: block;
  height: 4px;
  width: 36px;
  border-radius: 2px;
  background: $color;
}

.burger-bun-top {
  top: 0;
  transform-origin: 34px 2px;
}

.burger-bun-bot {
  bottom: 0;
  width: 20px;
  right: 0px;
  transform-origin: 34px 2px;
}

//.burger-filling {
//  @include transition(all,($animation/2.5),ease-in-//out);
//}
// relative parent is the button
.burger-filling {
  width: 28px;
  right: 0px;
  top: 12px;
}

// burger ring container
.burger-ring {
  position: absolute;
  top: 0;
  left: 0;
  width: 68px;
  height: 68px;
}

.svg-ring {
  width: 68px;
  height: 68px;
}

// bun animations 
.hamburglar.is-open {
  .burger-bun-top {
    animation: bun-top-out $animation linear normal;
    animation-fill-mode: forwards;
  }
  .burger-bun-bot {
    width: 36px;
    animation: bun-bot-out $animation linear normal;
    animation-fill-mode: forwards;
  }
}

.hamburglar.is-closed {
  .burger-bun-top {
    animation: bun-top-in $animation linear normal;
    //animation-fill-mode: forwards;
  }
  .burger-bun-bot {
    animation: bun-bot-in $animation linear normal;
    //animation-fill-mode: forwards;
  }
}

@keyframes bun-top-out {
  0% {
    left: 0;
    top: 0;
    transform: rotate(0deg);
  }
  20% {
    left: 0;
    top: 0;
    transform: rotate(15deg);
  }
  80% {
    left: -5px;
    top: 0;
    transform: rotate(-60deg);
  }
  100% {
    left: -5px;
    top: 1px;
    transform: rotate(-45deg);
  }
}

@keyframes bun-bot-out {
  0% {
    left: 0;
    transform: rotate(0deg);
  }
  20% {
    left: 0;
    transform: rotate(-15deg);
  }
  80% {
    left: -5px;
    transform: rotate(60deg);
  }
  100% {
    left: -5px;
    transform: rotate(45deg);
  }
}

@keyframes bun-top-in {
  0% {
    left: -5px;
    bot: 0;
    transform: rotate(-45deg);
  }
  20% {
    left: -5px;
    bot: 0;
    transform: rotate(-60deg);
  }
  80% {
    left: 0;
    bot: 0;
    transform: rotate(15deg);
  }
  100% {
    left: 0;
    bot: 1px;
    transform: rotate(0deg);
  }
}

@keyframes bun-bot-in {
  0% {
    left: -5px;
    transform: rotate(45deg);
  }
  20% {
    left: -5px;
    bot: 0;
    transform: rotate(60deg);
  }
  80% {
    left: 0;
    bot: 0;
    transform: rotate(-15deg);
  }
  100% {
    left: 0;
    transform: rotate(0deg);
  }
}

// burger filling
.hamburglar.is-open {
  .burger-filling {
    animation: burger-fill-out $animation linear normal;
    animation-fill-mode: forwards;
  }
}

.hamburglar.is-closed {
  .burger-filling {
    animation: burger-fill-in $animation linear normal;
    //animation-fill-mode: forwards;
  }
}

@keyframes burger-fill-in {
  0% {
    width: 0;
    left: 36px;
  }
  40% {
    width: 0;
    left: 40px;
  }
  80% {
    width: 36px;
    left: -6px;
  }
  100% {
    width: 36px;
    left: 0px;
  }
}

@keyframes burger-fill-out {
  0% {
    width: 36px;
    left: 0px;
  }
  20% {
    width: 42px;
    left: -6px;
  }
  40% {
    width: 0;
    left: 40px;
  }
  100% {
    width: 0;
    left: 36px;
  }
}


/* header */
