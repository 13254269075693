.photo_hotel {
  width: 59.4%;
  float: left;
}
.photo_hotel,
.item_hotel .slick-list {
  @include mq_max(992) {
    width: 100%;
    height: 400px !important;
    overflow: hidden;
  }
  @include mq_max(767) {
    height: 350px !important;
  }
  @include mq_max(480) {
    height: 350px !important;
  }
}

.item_hotel {
  overflow: hidden;
  background-color: #0d1b3d;
  //min-height: 655px;
  .photo_hotel {
    height: 100%;
  }
  &:nth-child(even) {
    background-color: #000;
  }
  .slick-list,
  .slick-track {
    height: 100%;
    //width: 100% !important;
    // img {
    //   height: 100%;
    // }
  }
  .slide {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
        overflow: hidden;
  }
  .btn-prev {
    margin-left: 15px;
  }
  .btn-next {
    margin-right: 15px;
  }
  @include mq_max(1800) {
    min-height: auto;
  }
}

.desc_hotel {
  padding: 40px 40px;
  overflow: hidden;
  color: #fff;
  .name_hotel {
    font-size: 28px;
    font-family: "Crimson Text", sans-serif;
    line-height: 30px;
    color: #fff;
    margin-bottom: 10px;
  }
  .contact_hotel {
    margin: 20px 0;
    padding: 18px 0;
    border-top: 1px solid #1B82D1;
    border-bottom: 1px solid #1B82D1; 
    overflow: hidden;
    color: #1B82D1;
    a {
      color: #1B82D1;
      transition: all .3s;
      &:hover {
        color: #BDD9FF
      }
    }
  }
  .address_hotel {
    width: 50%;
    float: left;
  }
  .sites {
    float: right;
    text-align: right;
  }
  .desc_hotel_text {
    h5 {
      font-size: 16px;
      font-weight: 700;
    }
    p {
      font-size: 14px;
      line-height: 22px;
    }
  }
  .btn_book {
    width: 171px;
    height: 40px;
    margin-top: 20px;
    display: inline-block;
    font-size: 18px;
    color: #fff;
    text-align: center;
    line-height: 38px;
    border: 1px solid #fff;
    border-radius: 3px;
    transition: all .4s;
    &:hover {
      color: #000;
      background-color: #fff;
    }
  }
  @include mq_max(767) {
    padding: 30px 25px;
  }
  @include mq_max(480) {
    padding: 25px 20px;
  }
}
