* {
  margin: 0px;
  padding: 0px;
  font-family: 'Oxygen', sans-serif;
}

a,
button,
input [type="submit"] {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
}
html,
body {
  height: 100%;
}
.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
main {
  flex: 1 0 auto;
}
footer {
  flex: 0 0 auto;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
}

.container {
  width: 1224px;
  margin: 0 auto;
  padding: 0 10px;
  @include mq_max (1224) {
    width: 100%;
  }
}

a,
a:hover,
a:focus,
a:active,
a.active {
  outline: 0;
  text-decoration: none;
}
input[type="submit"]:focus {
  outline: 0;
}
a,
input[type="submit"] {
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
}

button,
button:hover,
button:focus,
button:active,
button.active {
  outline: 0;
}
.img_responsive {
  max-width: 100%;
}

/* main */

main {
  margin-top: 124px;
  @include mq_max (768) {
    margin-top: 80px;
  }
}


/* elements */

.title_block {
  font-size: 35px;
  font-weight: 400;
  line-height: 45px;
  font-family: "Crimson Text", sans-serif;
  color: #fff;
  @include mq_max(1224) {
    font-size: 30px;
    font-weight: 400;
    line-height: 42px;
  }
}

.btn {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 1px 2px;
  border-radius: 3px;
  font-size: 18px;
  line-height: 50px;
  color: #fff;
  text-align: center;
  display: block;
  background-color: rgba(27, 130, 209, .8);
  transition: all .4s;
  &:hover {
    background-color: rgba(27, 130, 209, 1);
  }
}


/* slider control */

.btn_control {
  width: 38px;
  height: 76px;
  top: 50%;
  margin-top: -38px;
  border: none;
  background-color: transparent;
  position: absolute;
  z-index: 1;
  cursor: pointer;
}

.btn-prev,
.btn-next {
  &:before,
  &:after {
    content: '';
    position: absolute;
    background-color: #fff;
    width: 50px;
    height: 4px;
  }
}

.btn-prev {
  left: 0px;
  &:before {
    left: 0px;
    top: 52px;
    transform: rotate(45deg);
  }
  &:after {
    left: 0px;
    top: 19px;
    transform: rotate(315deg);
  }
}

.btn-next {
  right: 0px;
  &:before {
    right: 0px;
    top: 52px;
    transform: rotate(315deg);
  }
  &:after {
    right: 0px;
    top: 19px;
    transform: rotate(45deg);
  }
}

/* remove chest input */
input[type=text]::-ms-clear { display: none; }
